<template>
  <div class="content-box vision-box">
    <!-- <v-Header :isShowTitle="true" :showback="true" tittle='黄斑检查' @back='$router.go(-1)'>
     <template slot="tab">
      <div>
        <img src="../../assets/imgs/eye.png" alt="">{{ $store.state.eyeText }}
      </div>
     </template>
      <template slot="right-icon">
        <div @click="$router.go(-1)">
          上一题
        </div>
      </template>
    </v-Header> -->
    <div  class='page-content hasTitle bg-white' v-loading="loading">
      <div id="canvasCurveDiv" style="overflow-y: auto;">
        <div class='outer' :style="`height:${canvasHeight}px;width:${canvasWidth}px;`">
          <!-- <img src="../../assets/imgs/grid.png" alt="" :style="`height:${canvasHeight}px;`" /> -->
          <canvas id="canvasCurve" ref="canvas" class="canvas" :width="canvasWidth" :height="canvasHeight"
            @touchstart="handleMouseDown($event)" @touchmove="handleMouseMove($event)"
            @touchend="handleMouseUp($event)"></canvas>
          <img src="../../assets/imgs/arrow.png" alt="" class='arrow-icon'>
        </div>
      </div>
      <div class='flex bottom-box'>
        <div style="display:flex;justify-content:space-around;margin-top: 10px; margin-bottom: 30px; width: 100%;">
          <!-- <el-button @click="undo" type='primary'>撤销</el-button> -->
          <van-button class='left-btn' style="background: #FFB300;" round @click="clear" >清空</van-button>
          <van-button class='left-btn' @click="btnSave" round >保存</van-button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
@import "../../assets/less/vision.less";
</style>

<script>
import vHeader from '../../components/header.vue'
export default {
  components: {
    vHeader
  },
  props: ['currentIndex'],
  data() {
    return {
      ctx: null,
      canvasWidth: 10 / (this.$store.state.sys_param.Dpi * 1 / this.$store.state.sys_param.PPI * 2.45),
      canvasHeight: 10 / (this.$store.state.sys_param.Dpi * 1 / this.$store.state.sys_param.PPI * 2.45),
      moving: false,
      startCanvasX: 0,
      startCanvasY: 0,
      endCanvasX: 0,
      endCanvasY: 0,
      type: "moveLine",
      getType: "移动线",
      history: [],
      mousePosition: {
        x: Number,
        y: Number
      },
      beginPoint: {},
      stopPoint: {},
      polygonVertex: [],
      CONST: {
        edgeLen: 50,
        angle: 25
      },
      angle: "",
      lineWidth: 2,
      ctxColor: "#CC0001",
      colorShow: false,
      loading:false,
    };
  },

  mounted() {
    this.initChart();
    this.addHistoy();
    const box1 = document.getElementById("canvasCurveDiv")
    box1.scrollTo((this.canvasWidth - window.screen.width) / 2, 0);
    box1.style.overflow = 'hidden';
    if (this.currentIndex == 2) {
      this.ctxColor = '#1AA88B';
    }
    if (this.currentIndex == 3) {
      this.ctxColor = '#1890FF';
    }
  },
  created() {
  },

  methods: {
    initChart() {
      this.ctx = document.querySelector("#canvasCurve").getContext("2d");
    },
    handleMouseDown(e) {
      this.moving = true;
      const { target } = e;
      const canvasX = e.changedTouches[0].clientX - target.offsetLeft + (this.canvasWidth - window.screen.width) / 2;
      const canvasY = e.changedTouches[0].clientY - target.offsetTop - document.getElementById("hasTitleCanvas").offsetHeight ;
      this.startCanvasX = canvasX;
      this.startCanvasY = canvasY;
      this.beginPoint.x = e.changedTouches[0].pageX;
      this.beginPoint.y = e.changedTouches[0].pageY;
      if (this.type === "moveLine") {
        this.ctx.beginPath();
        this.ctx.moveTo(this.startCanvasX, this.startCanvasY);
      }
    },
    handleMouseMove(e) {
      if (this.moving) {
        this.showLastHistory();
        const start = { x: this.startCanvasX, y: this.startCanvasY };
        var w = e.changedTouches[0].clientX - this.startCanvasX;
        var h = e.changedTouches[0].clientX - this.startCanvasY;
        this.ctx.lineWidth = this.lineWidth;
        this.ctx.strokeStyle = this.ctxColor;
        this.mousePosition = {
          x: e.changedTouches[0].clientX + (this.canvasWidth - window.screen.width) / 2,
          y: e.changedTouches[0].clientY - document.getElementById("hasTitleCanvas").offsetHeight
        };
        this.drawMoveLineMove();
      }
    },
    handleMouseUp(e) {
      //添加画布
      this.addHistoy();
      this.moving = false;
    },

    // 跟随鼠标的移动线
    drawMoveLineMove() {
      this.ctx.lineTo(this.mousePosition.x, this.mousePosition.y);
      this.ctx.stroke();
    },
    // 显示最后一次画布
    showLastHistory() {
      if (this.history.length > 0) {
        this.ctx.putImageData(
          this.history[this.history.length - 1]["data"],
          0,
          0
        );
      }
    },
    // 鼠标up弹起保存
    addHistoy(data) {
      this.history.push({
        data: this.ctx.getImageData(0, 0, this.canvasWidth / 75, this.canvasHeight / 75)
      });
    },
    // 撤销
    undo() {
      if (this.history.length > 1) {
        this.history.pop();
        this.showLastHistory();
      }
    },
    clear() {
      this.history = [];
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      this.history.push({
        data: this.ctx.getImageData(0, 0, this.canvasWidth, this.canvasHeight)
      });
    },
    handleChangeColor(color) {
      this.ctxColor = color;
      this.colorShow = false;
    },
    dataURLtoBlob(base64Data) {
      let tmp = base64Data.split(',')
      if (tmp.length === 2) { // 如果base64包含头部的话，去掉base64的头
        tmp.shift()
      }
      var bytes = window.atob(tmp) // 转换为byte;
      // 处理异常,将ascii码小于0的转换为大于0
      var ab = new ArrayBuffer(bytes.length)
      var ia = new Uint8Array(ab)
      for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i)
      }
      return new window.Blob([ab], { type: 'image/png' })
    },
    //保存 base64转blob
    btnSave() {
      this.loading=true;
      var canvas = document.getElementById('canvasCurve')
      // var imgData = canvas.toDataURL({ format: 'png', quality: 1, width: 800, height: 600 })
      var imgData = canvas.toDataURL({ format: 'png', quality: 1 })
      // console.log('imgData',imgData)
      var strDataURI = imgData.substr(22, imgData.length).replace(/%0A|\s/g, '')
      let blob = this.dataURLtoBlob(strDataURI)
      let fileOfBlob = new File([blob], (new Date()).getTime() + '.png')
      var formData = new FormData()
      formData.append('file', fileOfBlob)
      formData.append('VisualId', this.$route.query.visualId)
      formData.append('No', this.currentIndex + 1)
      formData.append('EyeType', this.$store.state.eyeText == '左眼' ? 1 : 2)
      formData.append('Color', this.ctxColor)
      // 上传图片
      this.$httpProxy.downloadFile('/api/Visual/CalcImageArea', formData).then(async(res) => {
        if (res.data.Success) {
           let from = await this.$vlf.getItem('from');
           let userid = await this.$vlf.getItem('userId');
              if(from){
                  formData.append('UserId', userid)
                  formData.append('Status', 2)
                  formData.append('Area', res.data.ResData.ImageArea)
                  this.$httpProxy.post(`/${from}/CalcImageArea`,formData ).catch(e=>{
                  console.log(e)
              })
            }
          this.$httpProxy.post('/api/visual/InsertMaculars', {
            VisualId: this.$route.query.visualId,
            No: this.currentIndex + 1,
            EyeType: this.$store.state.eyeText == '左眼' ? 1 : 2,
            Image: res.data.ResData.File,
            
            Area: res.data.ResData.ImageArea
          }).then((res) => {
            if (res.data.Success) {
              if (this.currentIndex < 3) {
                this.$emit('changeIndex', this.currentIndex + 1)
              } else {
                if (this.$store.state.eyeText == '左眼') {
                  this.$store.commit('set_eye_text', '右眼')
                  this.$emit('changeIndex', 0)
                } else {
                  this.$router.go(-1);
                }
              }
            } else {
              this.$message.warning('出错了')
            }

          })

        } else {
          this.$message.warning('出错了')
        }

      }).finally(()=>this.loading=false)

    },
    save() {
      const canvas = document.getElementById("canvasCurve");
      const el = document.createElement('a');
      el.href = canvas.toDataURL();
      el.download = 'canvas';
      const event = new MouseEvent('click');
      el.dispatchEvent(event);
    },
  },
};
</script>


 
<style lang="less" scoped>
@import "../../assets/less/vision.less";
</style>